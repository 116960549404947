export const AuthHeader = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  if (user) {
    return {
      Authorization: `Bearer ${user.access_token}`,
    };
  }
};

export const In8itAuthHeader = () => {
  const caseMaster = JSON.parse(localStorage.getItem("caseMaster"));
  if (caseMaster) {
    return {
      Authorization: `Bearer ${caseMaster.token}`,
    };
  }
};
