import _axios from "../api";

export const getUserList = async (userType, qry) =>
  await _axios.get(`/admin/${userType}/list/${qry}`).then((response) => {
    return response.data;
  });

export const getAuthorityUserList = async (qry) =>
  await _axios.get(`/authority/User/${qry}`).then((response) => {
    return response.data;
  });

export const deleteUsers = async (id, value) =>
  await _axios.post(`/admin/${id}/${value}`).then((response) => {
    return response.data;
  });

export const deleteAuthorityUsers = async (id, value) =>
  await _axios.post(`/authority/user/${id}/${value}`).then((response) => {
    return response.data;
  });

export const addUser = async (webUserModel) =>
  await _axios
    .post(`/admin/add`, JSON.stringify(webUserModel))
    .then((response) => {
      return response.data;
    });

export const getIndividualUser = async (id) =>
  await _axios.get(`/admin/edit/${id}`).then((response) => {
    return response.data;
  });

export const updateUser = async (webUserModel) =>
  await _axios
    .post(`/admin/update`, JSON.stringify(webUserModel))
    .then((response) => {
      return response.data;
    });

export const modeUsers = async (theme) =>
  await _axios.post(`/mode/${theme}`).then((response) => {
    return response.data;
  });
