import { lazy, Suspense, useState } from "react";
import { Routes, Route } from "react-router-dom";
import LayoutDecider from "./layoutDecider";
import Loading from "./_loading";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import PrivateRoute from "./privateRoute";
import NoMatch from "./404";
import Unauthorized from "./403";
import FrontLayout from "./frontLayout";
import RoleBasedRedirect from "../../constants/RoleBasedRedirect";
import { ToastContainer } from "react-toastify";

const Login = lazy(() => import("../login"));
const ForgetPassword = lazy(() => import("../forgotPassword"));
const Dashboard = lazy(() => import("../admin/dashboard"));
const User = lazy(() => import("../admin/user"));
const UserList = lazy(() => import("../admin/userlist"));
const AuthorityUserList = lazy(() => import("../admin/authorityuser"));
const Profile = lazy(() => import("../profile"));
const ChangePassword = lazy(() => import("../changePassword"));
const PasswordReset = lazy(() => import("../passwordReset"));
const AuthorityList = lazy(() => import("../admin/authoritylist"));
const AuthorityUser = lazy(() => import("../admin/addauthority"));
const BasicDetail = lazy(() => import("../authority/basicDetail"));
const CaseManagerList = lazy(() => import("../authority/CaseManagerList"));
const Authoritylist = lazy(() => import("../authority/authorityUserList"));
const ActionDiaryList = lazy(() => import("../authority/ActionDiaryList"));
const ActionItemText = lazy(() => import("../authority/ActionItemText"));
const DashboardCaseManager = lazy(() =>
  import("../authority/DashboardCaseManager")
);
const ActionDiary = lazy(() => import("../authority/ActionDiary"));
const ActionFileUpload = lazy(() => import("../authority/ActionFileUpload"));

const Router = function () {
  const [role, setRole] = useState("AUTHORITY");
  return (
    <>
      <Routes>
        <Route path="/" element={<RoleBasedRedirect role={role} />} />
        <Route element={<FrontLayout />}>
          <Route
            path="/login"
            element={
              <Suspense fallback={<Loading />}>
                <GoogleReCaptchaProvider
                  reCaptchaKey={process.env.REACT_APP_GOOGLERECAPCHA_KEY}
                  scriptProps={{ defer: true, async: true }}
                >
                  <Login />
                </GoogleReCaptchaProvider>
              </Suspense>
            }
          />
          <Route
            path="/forgot-password"
            element={
              <Suspense fallback={<Loading />}>
                <GoogleReCaptchaProvider
                  reCaptchaKey={process.env.REACT_APP_GOOGLERECAPCHA_KEY}
                  scriptProps={{ defer: true, async: true }}
                >
                  <ForgetPassword />
                </GoogleReCaptchaProvider>
              </Suspense>
            }
          />
          <Route
            path="/password-reset"
            element={
              <Suspense fallback={<Loading />}>
                <GoogleReCaptchaProvider
                  reCaptchaKey={process.env.REACT_APP_GOOGLERECAPCHA_KEY}
                  scriptProps={{ defer: true, async: true }}
                >
                  <PasswordReset />
                </GoogleReCaptchaProvider>
              </Suspense>
            }
          />
        </Route>
        <Route element={<LayoutDecider />}>
          <Route
            path="/change-password"
            element={
              <Suspense fallback={<Loading />}>
                <PrivateRoute>
                  <ChangePassword />
                </PrivateRoute>
              </Suspense>
            }
          />
          <Route
            path="/profile"
            element={
              <Suspense fallback={<Loading />}>
                <PrivateRoute>
                  <Profile />
                </PrivateRoute>
              </Suspense>
            }
          />
          <Route
            index
            path="/dashboard"
            element={
              <Suspense fallback={<Loading />}>
                <PrivateRoute role="ADMIN">
                  <Dashboard />
                </PrivateRoute>
              </Suspense>
            }
          />
          <Route
            index
            path="/admin"
            element={
              <Suspense fallback={<Loading />}>
                <PrivateRoute role="ADMIN">
                  <UserList />
                </PrivateRoute>
              </Suspense>
            }
          />
          <Route
            index
            path="/authority"
            element={
              <Suspense fallback={<Loading />}>
                <PrivateRoute role="ADMIN">
                  <AuthorityList />
                </PrivateRoute>
              </Suspense>
            }
          />
          <Route
            index
            path="/:clienttype/user/:authorityId?"
            element={
              <Suspense fallback={<Loading />}>
                <PrivateRoute role="ADMIN">
                  <AuthorityUserList />
                </PrivateRoute>
              </Suspense>
            }
          />
          <Route
            index
            path="/:clienttype/:type/:id?"
            element={
              <Suspense fallback={<Loading />}>
                <PrivateRoute role="ADMIN">
                  <User />
                </PrivateRoute>
              </Suspense>
            }
          />
          <Route
            index
            path="/:clienttype/user/:type/:id?"
            element={
              <Suspense fallback={<Loading />}>
                <PrivateRoute>
                  <AuthorityUser />
                </PrivateRoute>
              </Suspense>
            }
          />
          <Route
            index
            path="/authority/dashboard"
            element={
              <Suspense fallback={<Loading />}>
                <PrivateRoute>
                  <DashboardCaseManager />
                </PrivateRoute>
              </Suspense>
            }
          />
          <Route
            index
            path="/authority/case/list"
            element={
              <Suspense fallback={<Loading />}>
                <PrivateRoute>
                  <CaseManagerList />
                </PrivateRoute>
              </Suspense>
            }
          />
          <Route
            index
            path="/case-manager/:type/:id?"
            element={
              <Suspense fallback={<Loading />}>
                <PrivateRoute>
                  <BasicDetail />
                </PrivateRoute>
              </Suspense>
            }
          />
          <Route
            index
            path="/authority/user"
            element={
              <Suspense fallback={<Loading />}>
                <PrivateRoute role="AUTHORITY">
                  <Authoritylist />
                </PrivateRoute>
              </Suspense>
            }
          />
          <Route
            index
            path="/actionDiary/list/:id?"
            element={
              <Suspense fallback={<Loading />}>
                <PrivateRoute>
                  <ActionDiaryList />
                </PrivateRoute>
              </Suspense>
            }
          />
          <Route
            index
            path="/actionDiary/:type/:id?"
            element={
              <Suspense fallback={<Loading />}>
                <PrivateRoute>
                  <ActionDiary />
                </PrivateRoute>
              </Suspense>
            }
          />
          <Route
            index
            path="/actionItemText/:type/:id?"
            element={
              <Suspense fallback={<Loading />}>
                <PrivateRoute>
                  <ActionItemText />
                </PrivateRoute>
              </Suspense>
            }
          />

          <Route
            index
            path="/actionItemFile/:type/:id?"
            element={
              <Suspense fallback={<Loading />}>
                <PrivateRoute>
                  <ActionFileUpload />
                </PrivateRoute>
              </Suspense>
            }
          />

          <Route path="*" element={<NoMatch />} />
        </Route>
        <Route path="/unauthorized" element={<Unauthorized />} />
        <Route path="*" element={<NoMatch />} />
      </Routes>
      <ToastContainer />
    </>
  );
};
export default Router;
