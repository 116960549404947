import { useSelector } from "react-redux";
import { AuthorityMenu } from "./authority-menu";
import { AuthorityUserMenu } from "./authorityuser-menu";
import MenuItem from "./menuItem";

const SideBarMenu = () => {
  const { user } = useSelector((state) => state.auth);

  const renderMenu = () => {
    if (user?.user_type === "AUTHORITY" && user?.role === "READONLY") {
      return AuthorityUserMenu.map((item, index) => (
        <MenuItem
          item={item}
          index={index}
          key={"menu" + index}
          data-bs-theme="dark"
        />
      ));
    } else if (user?.user_type === "AUTHORITY" && user?.role === "ADMIN") {
      return AuthorityMenu.map((item, index) => (
        <MenuItem
          item={item}
          index={index}
          key={"menu" + index}
          data-bs-theme="dark"
        />
      ));
    } else if (user?.user_type === "AUTHORITY" && user?.role === "READWRITE") {
      return AuthorityUserMenu.map((item, index) => (
        <MenuItem
          item={item}
          index={index}
          key={"menu" + index}
          data-bs-theme="dark"
        />
      ));
    } else {
      return; //<ReadOnlyMenu />;
    }
  };

  return (
    <div className="sidebar-menu">
      <ul className="menu" data-bs-theme="dark">
        <li className="sidebar-title" data-bs-theme="dark">
          {/* //   Menu */}
        </li>
        {renderMenu()}
      </ul>
    </div>
  );
};

// const ReadOnlyMenu = () => (
//   <div className="readonly-menu">
//     <p>Read-Only Access</p>
//     {/* Add more items for read-only access if needed */}
//   </div>
// );

export default SideBarMenu;
