import _axios from "./api";
import { LOGIN_SUCCESS, LOGOUT } from "./actions/types";
import * as ServiceAPI from "./services/auth.service";
import { AuthHeader } from "./services/auth.header";

const Interceptor = (store) => {
  const shouldIntercept = (error) => {
    return error.response && error.response.status === 401;
  };

  const setTokenData = (tokenData = {}, axiosClient = null) => {
    if (Object.keys(tokenData).length === 0) {
      throw new Error("Token data cannot be empty");
    }

    if (axiosClient) {
      axiosClient.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${tokenData.access_token}`;
    }
  };

  const handleTokenRefresh = async () => {
    const storeState = store.getState();
    const currentAccessToken = storeState?.auth.user.access_token;
    const currentRefreshToken = storeState?.auth.user.refresh_token;

    if (!currentAccessToken || !currentRefreshToken) {
      return Promise.reject(new Error("No access or refresh token available"));
    }

    try {
      const response = await _axios.post(`/refresh-token`, {
        accessToken: currentAccessToken,
        refreshToken: currentRefreshToken,
      });

      const data = response.data;
      const updatedUser = {
        ...storeState.auth?.user,
        ...data,
      };

      store.dispatch({
        type: LOGIN_SUCCESS,
        payload: { user: updatedUser },
      });

      return data;
    } catch (error) {
      ServiceAPI.logout();
      store.dispatch({
        type: LOGOUT,
      });

      return Promise.reject(error);
    }
  };

  const attachTokenToRequest = (request, token) => {
    request.headers["Authorization"] = "Bearer " + token;
  };

  const applyInterceptor = (axiosClient, customOptions = {}) => {
    let isRefreshing = false;
    let failedQueue = [];

    const options = {
      attachTokenToRequest,
      handleTokenRefresh,
      setTokenData,
      shouldIntercept,
      ...customOptions,
    };

    const processQueue = (error, token = null) => {
      failedQueue.forEach((prom) => {
        if (error) {
          prom.reject(error);
        } else {
          prom.resolve(token);
        }
      });

      failedQueue = [];
    };

    const interceptor = async (error) => {
      if (!options.shouldIntercept(error)) {
        return Promise.reject(error);
      }

      const originalRequest = error.config;

      if (originalRequest._retry || originalRequest._queued) {
        return Promise.reject(error);
      }

      if (isRefreshing) {
        return new Promise((resolve, reject) => {
          failedQueue.push({ resolve, reject });
        })
          .then((token) => {
            originalRequest._queued = true;
            options.attachTokenToRequest(originalRequest, token);
            return axiosClient(originalRequest);
          })
          .catch((err) => Promise.reject(err));
      }

      originalRequest._retry = true;
      isRefreshing = true;

      return new Promise((resolve, reject) => {
        options
          .handleTokenRefresh()
          .then((tokenData) => {
            const accessToken = tokenData.access_token;
            options.setTokenData(tokenData, axiosClient);
            options.attachTokenToRequest(originalRequest, accessToken);
            processQueue(null, accessToken);
            resolve(axiosClient(originalRequest));
          })
          .catch((err) => {
            processQueue(err, null);
            reject(err);
          })
          .finally(() => {
            isRefreshing = false;
          });
      });
    };

    _axios.interceptors.request.use(
      (config) => {
        if (!config.headers.Authorization) {
          config.headers.Authorization = AuthHeader().Authorization;
        }
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
    axiosClient.interceptors.response.use(
      (response) => response,
      (error) => {
        if (options.shouldIntercept(error)) {
          return interceptor(error);
        }
        return Promise.reject(error);
      }
    );
  };

  return { applyInterceptor };
};

export default Interceptor;
