import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import AdminLayout from "../admin/layout";
import AuthorityLayout from "../authority/AuthorityLayout";
import FrontLayout from "./frontLayout";

const LayoutDecider = () => {
  const { user } = useSelector((state) => state.auth);
  if (user?.access_token) {
    const token = user.user_type;
    return token === "ADMIN" ? (
      <AdminLayout />
    ) : token === "AUTHORITY" ? (
      <AuthorityLayout />
    ) : token === "AUTHORITY_USER" ? (
      <AuthorityLayout />
    ) : (
      <FrontLayout />
    );
  }

  return <Navigate to="/login" />;
};

export default LayoutDecider;
