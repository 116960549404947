export const AuthorityMenu = [
  {
    title: "Dashboard",
    url: "/authority/dashboard",
    icon: "pi pi-th-large",
  },
  {
    title: "Authority User",
    url: "/authority/user",
    icon: "pi pi-user",
  },
  {
    title: "Cases List",
    url: "/authority/case/list",
    icon: "pi pi-home",
  },
];
