import axios from "axios";

export const isolatedAxios = axios.create({
  baseURL: `${process.env.REACT_APP_SERVER_URL}/`,
  headers: { "Content-Type": "application/json" },
});
const _axios = axios.create({
  baseURL: `${process.env.REACT_APP_SERVER_URL}/`,
  headers: { "Content-Type": "application/json" },
});

export default _axios;
