import _axios, { isolatedAxios } from "../api";

export const register = async (registerObj) =>
  await _axios
    .post("/register", JSON.stringify(registerObj))
    .then((response) => {
      return response.data;
    });

export const login = async (dataObj) =>
  await isolatedAxios
    .post(`/login`, JSON.stringify(dataObj))
    .then((response) => {
      return response.data;
    });

export const forgotPassword = async (forgotPasswordObj) =>
  await isolatedAxios
    .post(`/forgot-password`, JSON.stringify(forgotPasswordObj))
    .then((response) => {
      return response.data;
    });

export const changePassword = async (
  currentPassword,
  newPassword,
  confirmNewPassword
) =>
  await _axios
    .post(
      `/change-password`,
      JSON.stringify({
        currentPassword,
        newPassword,
        confirmNewPassword,
      }),
      {}
    )
    .then((response) => {
      return response.data;
    });

export const passwordReset = async (resetPasswordObj) =>
  await isolatedAxios
    .post(`/password-reset`, JSON.stringify(resetPasswordObj))
    .then((response) => {
      return response.data;
    });

export const logout = () => {
  console.log("logout");
  localStorage.removeItem("user");
  localStorage.removeItem("persist:Case");
};

export const getProfile = async () =>
  await _axios.get(`/profile`, {}).then((response) => {
    return response.data;
  });

export const saveProfile = async (profileObj) =>
  await _axios
    .post(`/profile`, JSON.stringify(profileObj), {})
    .then((response) => {
      return response.data;
    });

export const impersonateUser = async (webUserId) => {
  try {
    const response = await _axios.post(`/impersonate/${webUserId}`);
    return response.data.data;
  } catch (error) {
    console.error("Error in API call:", error);
    throw error;
  }
};

export const impersonateBack = async () => {
  try {
    const response = await _axios.post(`/impersonate/back`);
    return response.data.data;
  } catch (error) {
    console.error("Error in API call:", error);
    throw error;
  }
};
