export const adminMenu = [
  {
    title: "Dashboard",
    url: "/dashboard",
    icon: "pi pi-th-large",
  },
  {
    title: "Admin List",
    url: "/admin",
    icon: "pi pi-user",
  },

  {
    title: "Authority",
    url: "/authority",
    icon: "pi pi-home",
  },
  // {
  //   title: "Case Manager",
  //   url: "/authority/case/list",
  //   icon: "pi pi-briefcase",
  // },
];
