import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

const PrivateRoute = ({ children, role }) => {
  const { isLoggedIn, user } = useSelector((state) => state.auth);
  if (!isLoggedIn) return <Navigate to={{ pathname: "/login" }} />;

  var token = user.user_type;
  if (role && role !== token)
    return <Navigate to={{ pathname: "/unauthorized" }} />;

  return children;
};
export default PrivateRoute;
