import { IN8IT_LOGIN_SUCCESS, IN8IT_LOGIN_FAIL } from "../actions/caseTypes";

const user = JSON.parse(localStorage.getItem("caseMaster"));
// console.log(caseMaster);
const initialState = {
  isLoggedIn: !!user, // Set to true if caseMaster exists, false otherwise
  user: user || null, // Set caseMaster if it exists, otherwise null
};

export default function in8itauth(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case IN8IT_LOGIN_SUCCESS:
      localStorage.setItem("user", JSON.stringify(payload.caseMaster));
      return {
        ...state,
        isLoggedIn: true,
        user: payload.user,
      };
    case IN8IT_LOGIN_FAIL:
      const user = JSON.parse(localStorage.getItem("user")); // Clear caseMaster on login failure
      delete user.token;
      delete user.refreshToken;
      localStorage.setItem("user", JSON.stringify(user));
      return {
        ...state,
        user: user,
      };
    default:
      return state;
  }
}
