import {
  ADD,
  ADD_CHILD,
  REMOVE,
  REPLACE,
  CLEAR_FORM_STATE,
} from "../actions/applicationType";

const initialState = {};

const applicationreducers = (state = initialState, action) => {
  const { type, payload, parentKey, propertyKey } = action;

  switch (type) {
    case CLEAR_FORM_STATE: {
      return {};
    }
    case REPLACE: {
      return payload;
    }
    case ADD:
      return {
        ...state,
        [propertyKey]: payload,
      };
    case ADD_CHILD:
      return {
        ...state,
        [parentKey]: {
          ...state[parentKey],
          [propertyKey]: payload,
        },
      };
    case REMOVE: {
      // Clone the state to avoid mutating it directly
      const tempState = { ...state };

      if (parentKey && tempState[parentKey]) {
        // If parentKey is present, remove it
        delete tempState[parentKey];

        if (
          propertyKey &&
          tempState[parentKey] &&
          tempState[parentKey][propertyKey]
        ) {
          // If propertyKey is present, remove it under parentKey
          delete tempState[parentKey][propertyKey];
        }
      }

      return tempState;
    }
    default:
      return state;
  }
};

export default applicationreducers;
