import { adminMenu } from "./admin-menu";
import MenuItem from "./menuItem";

const SideBarMenu = () => {
  return (
    <div className="sidebar-menu">
      <ul className="menu">
        {/* <li className="sidebar-title">Menu</li> */}
        {adminMenu.map((item, index) => {
          return <MenuItem item={item} index={index} key={"menu" + index} />;
        })}
      </ul>
    </div>
  );
};
export default SideBarMenu;
