import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import _axios from "./redux/api";
import { BrowserRouter } from "react-router-dom";
import applyAxiosInterceptor from "./redux/interceptor";
import App from "./App";
import "bootstrap/dist/js/bootstrap.js";
import "./css/style.scss";
import "primeicons/primeicons.css";
import "./assets/scss/themes/dark/app-dark.scss";
import "./assets/static/js/components/dark.js";
import "./assets/scss/app.scss";
import { store, persistedStore } from "./redux/store";
import "react-toastify/dist/ReactToastify.css";
import { PersistGate } from "redux-persist/integration/react";

applyAxiosInterceptor(store).applyInterceptor(_axios);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistedStore}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </PersistGate>
    </Provider>
  </React.StrictMode>
);
